
.grid-item-content {
  height: 100%;
  margin-bottom: 30px;
  overflow:hidden;
  cursor:pointer;
  position: relative;
  transition:all .6s;

  .hover {
    transition:all .6s;
    &:hover{
        opacity: 1;
      }
  }
  
  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: 0 10px;
  }
  .overlay-2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    padding: 0 10px;
  }
}

.hover {
  position: absolute;
  width:100%;
  height: 100%;
  background: $blue;
  opacity: 0;
}

#top .av-caption-style-overlay .av-masonry-entry .av-masonry-entry-title {
    color: white!important;
}









