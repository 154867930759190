//section news
@include media-breakpoint-only(lg) {
  .s-news {
    display: block;
    background: $s-lightgray;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .btn {
    	margin: 0 2px 5px 0;
    }
  }

  #carouselNews {
    margin-top: 2rem;
    margin-bottom: -6rem;
    .carousel-indicators {
        justify-content: left;
      }

  }

  .news__container {
  	padding-left: 60px;
  }
  .news__img {
  	height: 400px;
  	overflow: hidden;
    position: relative;
    img {
        position: absolute;
        top: -9999px;
        bottom: -9999px;
        left: -9999px;
        right: -9999px;
        margin: auto;

    }
  }


}
