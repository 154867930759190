//section news all devices



.news__type {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 700;
  color: rgba(0,0,0, .25);
}

.news__date {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0,0,0, .65);
}

.news__title {
  font-weight: 300;
  color: white;
}

#carouselNews {
  .carousel-indicators {
    justify-content: center;
    margin-left: 40px;
    li {
      width: 15px;
      height: 15px;
      border-radius: 15px;
    }
  }
}
