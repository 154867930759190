@media (min-width: 992px) {
	.s-media-2 {
		background-image: url("../images/media_bg.png");
		background-repeat: repeat-y;
		background-position: 50%;
	}
	.s-media-2-blue {
	    background-image: url(../images/media_bg_blue.png);
	    background-repeat: repeat-y;
	    background-position: 50%;
	}
	.evento__holder {
		margin-top: -20px;
	  margin-right: -15px;
	}
}
