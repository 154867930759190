//
// Poppins Light
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Light'),
       local('Poppins-Light'),
       url('#{$poppins-font-path}/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
