@media (min-width: 992px) {


/* option 2 **************************************** */

	.s-fornitori-2 {
		/*Bandi Home*/
		.bandi__holder {
		    margin-top: -50px;
		}
    .bandi__holder img {
        position: relative;
        z-index: 9;
    }
		background-image: url("../images/fornitori-section-background.png");
	  background-position: 50%;
	  background-repeat: repeat-y;
	  position:relative;
	    	&.s-fornitori-2-green {
				background-image: url(../images/fornitori-section-background-green.png);
				&:after {
					background-image: url(../images/fornitori-section-top-background-green.png);
				}
	    	}
	      &:after {
		    	content: '';
		    	position: absolute;
		    	width: 100%;
		    	height: 50px;
		    	top: -50px;
		    	right:0;
		    	left: 0;
		    	background-image: url(../images/fornitori-section-top-background.png);
			    background-position: 50%;
			    background-repeat: repeat-y;
		    }
				.fornitori-col{
					background-color: transparent;
				}
	    .bandi-col{
	    		position: relative;
	    	    &:after {
				    	content: '';
				    	position: absolute;
				    	width: 20px;
				    	height: 2px;
				    	background-color: #c9e5f7;
				    	top: 0;
				    	right:0;
				    	left: -85px;
				    	z-index: 99999;
		    }
	    }

	    .fornitori-col {
			padding-right: 120px;
		}

		.btn {
		    margin: 0 2px 5px 0;
		}
	}

	.btn-outline-blue {
		border-width: 2px;
		border-color: $green;
		color: $green;
	}
	/* / option 2 **************************************** */
}
