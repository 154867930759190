//
// Poppins Black
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Black'),
       local('Poppins-Black'),
       url('#{$poppins-font-path}/Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
