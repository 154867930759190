@media (min-width: 992px) {
	.s-sostenibilita {

		height: 850px;
	}

	.sostenibilita__block {
		height: 750px;
		max-width: 100%;
	  width: 100%;
	}

	.btn-outline-bianco {
		border-width: 2px;
	}
}
