//section news
@include media-breakpoint-only(sm) {

  #carouselNews {
    .carousel-indicators {
      justify-content: center;
      margin-left: 60px;
      li {
        width: 15px;
        height: 15px;
        border-radius: 15px;
      }
    }
    .news__img {
    	display: none;
    }
  }
  .news__container {
  	min-height: 375px;
  }
}

@include media-breakpoint-only(xs) {
  .news__img {
    display: none;
  }
  .news__container {
  	min-height: 425px;
  }
}
