
.top-navbar-replace {
  svg {
    float: right;
    width: 30px;
    fill: $secondary;
    .st0 {
      fill: $secondary;
    }
  }
}


.hamburger {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 10000;
  padding: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.hamburger__icon {
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
}

.hamburger__icon, .hamburger__icon:before, .hamburger__icon:after {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  -webkit-transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.color-logo .hamburger__icon, .color-logo .hamburger__icon:before, .color-logo .hamburger__icon:after {
  background-color: #a1a0a4;
}


.hamburger__icon:before, .hamburger__icon:after {
  position: absolute;
  content: "";
}
.hamburger__icon:before {
  top: -7px;
}
.hamburger__icon:after {
  top: 7px;
}
.active .hamburger__icon {
  background-color: transparent;
}
.active .hamburger__icon:before, .active .hamburger__icon:after {
  background-color: #fff;
}
.active .hamburger__icon:before {
  -moz-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -webkit-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.active .hamburger__icon:after {
  -moz-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -webkit-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}

.fat-nav {
  top: 0;
  left: 0;
  z-index: 999;
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  background: #666;
  -moz-transform: scale(.2);
  -ms-transform: scale(.2);
  -webkit-transform: scale(.2);
  transform: scale(.2);
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.fat-nav__wrapper {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  padding-top: 76px;
}
.fat-nav.active {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.fat-nav__title {
  color:white;
}

.fat-nav__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.fat-nav__item {
  line-height: 20px;
  margin-bottom: 10px;

  a {
    color: rgba(255,255,255, .5);
    &:hover {
      color: rgba(255,255,255,1);
    }

  }
}





@include media-breakpoint-down(md) {

  .wrap-collabsible {

  }

  input[type='checkbox'] {
    display: none;
  }

  .lbl-toggle {
    display: block;
    color: $white;
    font-size: .9rem;
    text-transform: uppercase;
    text-align: left;
    margin-left: 1rem;
    padding-top: 1rem;
    cursor: pointer;
    transition: all 0.25s ease-out;

  }

  .lbl-toggle:hover {

  }

  .lbl-toggle::after {
    content: ' ';
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid currentColor;
    float: right;
    vertical-align: middle;
    margin-right: 1rem;

    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }

  .toggle:checked + .lbl-toggle::after {
    transform: rotate(90deg) translateX(-3px);
  }

  .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 350px;
  }

  .toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .collapsible-content .content-inner {
    background: darken($s-darkgray, 10%);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }



  .fat-nav {
    .row {
      &.no-gutters-nav {
        margin-right: -15px;
        margin-left: -15px;

        & > [class^="col-"],
        & > [class*=" col-"] {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }
  }

  .fat-nav__title {
    border-bottom: 1px solid rgba(255, 255,255, .2);
    padding: 1rem;
    margin-bottom: 0;
    font-size: 0.9em;
  }
  .fat-nav__item {
    padding: 0 1rem;
  }
}
