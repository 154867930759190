/*** tema verde del ciclo nucleare *******/

.s-ciclo-nucleare {
	&.bg-success {

		.centrale{opacity:0.5;fill:$white;}
		.finestra{fill:$white;}
		.ruota{fill:$white;}
		.braccio{fill:$white;}
		.pala{fill:$white;}
		.corpo{fill:$white;}
		.cingoli{fill:$white;}
		.cabina{fill:$white;}

		
		.bidone{fill:$white;}

		.sicurezza{fill:$white;}

		.muletto{fill:$white;}

		.face {fill:$white;}
		.helmet {fill:$white;}
		.body {fill:$white;}
		.overalls {fill:$white;}

		.s-ciclo-nucleare {
		  background: $green;

		}

		.ciclo-nucleare {
			background: $green;

		}



		.ciclo-nucleare--item {
			svg {
				width: 150px;
			}

			@include transition(all .5s ease);
			&:hover {
				background: $white;
				h5 {
					@include transition(all .5s ease);
					color: $green;
				}
				.finestra {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.terreno {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.5);
				}
				.ruota {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.centrale {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.5);
				}
				.braccio {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.pala {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.corpo {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.cingoli {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.cabina {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}

				.bidone {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}

				.sicurezza {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}

				.centrale-scomparsa {
					@include transition(all .5s ease);
					fill:$green;
				}
				.muletto {
					@include transition(all .5s ease);
					fill:rgba(96, 176, 70, 0.8);
				}
				.face,
				.body,
				.helmet,
				.overalls {
					@include transition(all .5s ease);
					fill:$green;
				}
			}
			a {
				text-decoration: none;
			}
		}





	}
}
