.daterange {
	width: 250px;
}
#swiper_news_gallery {
	padding-bottom: 60px;
}





  