@include media-breakpoint-up(lg) {

	.s-deposito-nazionale--outer {
		background: lightgray;

	}

	.s-deposito-nazionale--block {
		margin: -75px -95px;
		padding:  75px 95px;
		background: white;
		z-index: 2;
	    position: relative;
	   // background-image: url(../images/servizi_bg.jpg);
	}
}
