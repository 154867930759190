.ha-items {
  width: 100%;
  height: 370px;
  .ha-item {
    width: 100%;
    height: 370px;
    .ha-content {
      padding: 20px;
    }
    &:hover {
      cursor: pointer;
    }
    &:nth-child(1) {
      background-color: gray;
    }
    &:nth-child(2) {
      background-color: lightgray;
    }
    &:nth-child(3) {
      background-color: #FF6600;
    }
    &:nth-child(4) {
      background-color: #FF9900;
    }
    &:nth-child(5) {
      background-color: #FFBB00;
    }
    .accordion-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
	}
}

.haccordion {
  display: flex;
  min-height: 50vh;
  max-height: 50vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
}

li.haccordion-section {
  flex: 1;
  display: flex;
  align-items: stretch;
  padding: 20px;
  background: $green;
  cursor: pointer;
  transition: all .5s ease;

  &:hover {
    background: darken($green, 3);
  }

  &.active {
    flex: 5;
    background: lightgray;
    cursor: default;

    h2 {
      color: darken($green, 3);
    }

    .section-content {
      flex: 4;
      opacity: 1;
      transform: scaleX(1);
      color: darkgray;
    }
  }

  .section-title {
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
    color: white;

    h2 {
      margin: 0;
    //  transform: rotate(-90deg);
      white-space: nowrap;
    }
  }

  .section-content {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    opacity: 0;
    transition: all .25s .1s ease-out;
  }
}

//tab switcher

@mixin size($w, $h) {
  width: $w;
  height: $h;
}

@mixin flex($dis, $x, $y, $dir) {
  display: $dis;
  justify-content: $x;
  align-items: $y;
  flex-direction: $dir;
}



.switch_wrapper {
  @include size(100%,100%);
  @include flex(flex, null, null, column);



  //border-radius: 10px;
  overflow: hidden;

  .sg-header {
    //background: lighten(red, 20%);

    //@include size(100%, 75px);

    //border-radius: 10px 10px 0 0;

    @include flex(flex, null, null, column);


    .mic {
      @include size(15%, 7px);

      background: lighten(cornflowerblue, 10%);
      border-radius: 120px;
    }

    .sg-nav {
      @include size(50%, 100%);
      @include flex(flex, null, null, null);
      background-color: $chiaro;
      position: relative;
      border-radius: 50px;
      .tab {
        @include size(50%, 100%);
        @include flex(flex, center, center, null);
        color: $green;

        &.active {
          color: $white;
        }
      }

      .indicator {
        @include size(50%, 100%);
        border-radius: 50px;
        position: absolute;
        bottom: 0;
        background: $green;

        transition: 0.3s ease;
      }
    }

    .block {
      //@include size(85%, 40%);

      //border-radius: 4px;
      //background: lighten(cornflowerblue, 10%);
      cursor: pointer;
      z-index: 10;
      padding: 10px;
      font-size: 18px;
      font-weight: 400;
    }
  }

  .main {

    flex: 1;

    @include size(200%, 100%);

    .tab_reel {
      @include size(100%, null);
      @include flex(flex, space-between, null, null);

      transition: 0.5s cubic-bezier(0.18, 1.14, 0.5, 1.18);

      .tab_panel1, .tab_panel2 {
        @include size(50%, null);
        background: $white;
        padding:  0;
        margin-top: 1rem;
        .svg-holder {
          margin:auto;
          @include size(50%, 50%);
          svg {
            .leaves {
              fill: $green;
            }
            .globe {
              fill: gray;
            }
            .branches {
              fill: lightgray;
            }

          }
        }
      }
    }

    .card {
      @include size(100%, auto);

      padding: 10px;
      margin-bottom: 0.7rem;
      //border-radius: 6px;
      background: white;
      //box-shadow: 0px 5px 15px rgba(cornflowerblue, 0.3);
    }

    .card_large {
      figure {
        @include size(100%, 100px);

        margin: 0;
        background: lighten(cornflowerblue, 20%);
      }

      .desc {
        padding-left: 0px;
      }
    }

    .card_list {
      @include flex(flex, null, center, row);

      figure {
        @include size(40px, 40px);

        border-radius: 120px;
        background: lighten(cornflowerblue, 20%);
        margin: 0;
      }

      .desc {
        flex: 1;
      }
    }

    .desc {
      @include size(100%, null);

      padding: 10px;

      .block {
        @include size(40%, 7px);

        margin-bottom: 5px;
        background: lighten(cornflowerblue, 10%);

        &:nth-child(2) {
          width: 35%;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.tab1 {
  &.active {
    ~ .indicator {
      left: 0;
    }
  }
}

.tab2 {
  &.active {
    ~ .indicator {
      left: 50%;
    }
  }
}
