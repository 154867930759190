  // middle line 
  .line {
    position: relative;
    &:before {
      content: "";
      display: block;
      border-top: solid 1px $gray-400;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      z-index: 1;
    }
  span {
      background: $white;
      padding-right: 20px;
      position: relative;
      z-index: 5;
    }
  }

//customize multimedia card 
.multimedia {
    &.card {
    border-bottom: 2px solid transparent;
    a {
      &.card-hyperlink  {
        position: absolute;
        top: 0; left: 0;
        height: 100%; width: 100%;
      }
    }
    .card-img-wrap {
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: rgba(255,255,255,0.3);
        opacity: 0;
        transition: opacity .25;
      }
      img {
        transition: transform .6s;
        width: 100%;
      }
    }
    &:hover {
      cursor: pointer;
      border-bottom: 2px solid $green!important;
      img {
        transform: scale(1.2);
      }
      .card-img-wrap {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

// numbered badge

.topright-badge {
  width: 0;
  height: 0;
  border-top: 50px solid $green;
  border-left: 50px solid transparent;
  position: absolute;
  right:0px;
  top:0px;
  z-index: 5;
  span {
    position: absolute;
    top: -45px;
    left: -20px;
    color: $white;
    font-size: 14px;
  }
}










