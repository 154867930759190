
/* --------------------------------

Main Components

-------------------------------- */

.jtline {
  opacity: 0;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
  &::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'mobile';
    display: none;
  }
  &.loaded {
    /* show the timeline after events position has been set (using JavaScript) */
    opacity: 1;
  }
  .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
  .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
    &::after {
      /* these are used to create a shadow effect at the sides of the timeline */
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
    }
    &::before {
      /* these are used to create a shadow effect at the sides of the timeline */
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
      left: 0;
      background-image: -webkit-linear-gradient(left, #eaeaea, rgba(248, 248, 248, 0));
      background-image: linear-gradient(to right, #eaeaea, rgba(248, 248, 248, 0));
    }
    &::after {
      right: 0;
      background-image: -webkit-linear-gradient(right, #eaeaea, rgba(248, 248, 248, 0));
      background-image: linear-gradient(to left, #eaeaea, rgba(248, 248, 248, 0));
    }
  }
  .events {
    /* this is the grey line/timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    /* width will be set using JavaScript */
    background: #dfdfdf;
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
  }
  .filling-line {
    /* this is used to create the green line filling the timeline */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $green;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .events a {
    font-family: $poppins;
    position: absolute;
    bottom: 30px;
    z-index: 2;
    text-align: center;
    //font-size: 1.3rem;
    padding-left: 15px;
    color: #383838;
    /* fix bug on Safari - text flickering while timeline translates */
    // -webkit-transform: translateZ(0);
    // -moz-transform: translateZ(0);
    // -ms-transform: translateZ(0);
    // -o-transform: translateZ(0);
    transform: translateZ(0);
    &::after {
      /* this is used to create the event spot */
      content: '';
      position: absolute;
      left: 50%;
      right: auto;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: -39px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid #dfdfdf;
      background-color: #f8f8f8;
      -webkit-transition: background-color 0.3s, border-color 0.3s;
      -moz-transition: background-color 0.3s, border-color 0.3s;
      transition: background-color 0.3s, border-color 0.3s;
    }
    &::before {
          content:'';
          width: 2px;
          height: 13px;
          background: #dfdfdf;
          top: 30px;
          position: absolute;
          left: 50%;
          right: auto;
          margin-left: -1px;
        -webkit-transition: background-color 0.5s, border-color 0.5s;
        -moz-transition: background-color 0.5s, border-color 0.5s;
        transition: background-color 0.5s, border-color 0.5s;
      }
  }
}

.no-touch .jtline .events a:hover::after {
  background-color: $green;
  border-color: $green;
}

.jtline .events a {
  &.selected {
    font-weight: 700;
    color: $green;
    pointer-events: none;
    &::after {
      background-color: $green;
      border-color: $green;
    }
    &::before {
      content: '';
          width: 2px;
          height: 24px;
          background: #60b146;
          top: 20px;
          position: absolute;
          left: 50%;
          right: auto;
          margin-left: -1px;
    }
  }
  &.older-event::after {
    border-color: $green;
  }
}

@media only screen and (min-width: 1100px) {
  .jtline {
    margin: 6em auto;
    &::before {
      /* never visible - this is used in jQuery to check the current MQ */
      content: 'desktop';
    }
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
  &::after {
    /* arrow icon */
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: url(../img/cd-arrow.svg) no-repeat 0 0;
  }
  &.prev {
    left: 0;
    -webkit-transform: translateY(-50%) rotate(180deg);
    -moz-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
    -o-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
  }
  &.next {
    right: 0;
  }
}

.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}

.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
  &::after {
    background-position: 0 -16px;
  }
}

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf;
}

.jtline .events-content {
  max-width: 800px;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  //overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
  li {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    &.selected {
      /* visible event content */
      position: relative;
      z-index: 2;
      opacity: 1;
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0);
    }
    &.enter-right, &.leave-right {
      -webkit-animation-name: cd-enter-right;
      -moz-animation-name: cd-enter-right;
      animation-name: cd-enter-right;
    }
    &.enter-left, &.leave-left {
      -webkit-animation-name: cd-enter-left;
      -moz-animation-name: cd-enter-left;
      animation-name: cd-enter-left;
    }
    &.leave-right, &.leave-left {
      -webkit-animation-direction: reverse;
      -moz-animation-direction: reverse;
      animation-direction: reverse;
    }
    > * {
      margin: 0 auto;
    }
  }
  h2 {
    font-family: $poppins;
    font-weight: 500;

  }
  em {
    display: block;
    font-style: italic;
    margin: 10px auto;
    border-bottom: 2px dashed #dfdfdf;
    &::before {
      content: '- ';
    }
  }
  p {
    //font-size: 1.4rem;
    color: #959595;
  }
  em, p {
    line-height: 1.6;
  }
}

@media only screen and (min-width: 768px) {
  .jtline .events-content {
    h2 {
      //font-size: 7rem;
    }
    em {
      //font-size: 2rem;
    }
    p {
      //font-size: 1.8rem;
    }
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}


@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}


@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}


@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}


@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}


@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
