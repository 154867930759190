/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
.navbar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $green;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, .5);
  svg {
    height: 50px;
    float: left;
    margin-left: -40px;
  }
}
