.first_pharagraph {
&:first-letter {
    float: left;
    font-size: 3.25em;
    margin-right: 10px;
    line-height: 1;
    text-transform: uppercase;
  }
}

.testo-lettera {
  position: relative;
  max-height: 250px;
  overflow: hidden;
  transition: max-height 1s ease;

  &.-expanded {
    max-height: 350vh;
  }
}

.testo-lettera:not(.-expanded)::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}
