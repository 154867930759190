.ico {
    display: block;
    margin: auto;
    width: 100px;
    height: 100px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    &.ico-green {
        fill:$green;
    }
    &.ico-blue {
        fill:$blue;
    }
    &.ico-white {
        fill:$white;
    }
    &.ico-lightblue {
        fill:lighten($blue, 25%);
        stroke:lighten($blue, 25%);
    }
    
    &.ico-bluegradient {
        fill: url(#blue-gradient) lighten($blue, 25%);

    }
    &.ico-colors-green-gray {
        --color-1: $green;
        --color-2: lighten($green, 10%);
        --color-3: lighten($green, 20%);
        --color-4: $secondary;
        --color-5: darken($chiaro, 5%);
        
      }
      &.ico-colors-blues {
        --color-1: $blue;
        --color-2: lighten($blue, 10%);
        --color-3: lighten($blue, 20%);
        --color-4: $secondary;
        --color-5: darken($chiaro, 5%);
        
      }
  }


  
  /* ==========================================
  Single-colored icons can be modified like so:
  .icon-name {
    font-size: 32px;
    color: red;
  }
  ========================================== */


  
  .icon-logo-sogin-white-footer {
    width: 0.431640625em;
  }
  
  .icon-rifiuti-prodotti {
    width: 1.2890625em;
  }
  
  .icon-rightarrow-666 {
    width: 0.5830078125em;
  }
  
  .icon-rightarrow-blue {
    width: 0.5830078125em;
  }
  
  .icon-rightarrow-fff {
    width: 0.5830078125em;
  }
  
  .icon-rightarrow-green {
    width: 0.5830078125em;
  }
  
  .icon-tac_scan {
    width: 1.0126953125em;
  }
  