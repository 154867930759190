@include media-breakpoint-up(lg) {
	.sg-management__managerpicture {
		height: auto;
	    overflow: hidden;
	    float: left;
	    margin-right: 20px;

	    &.sg-management__managerpicture__sm {
	    	max-width: 132px;
	    }
	    &.sg-management__managerpicture__lg {
	    	max-width: 215px;
	    }
	    img {
	    	width: 100%;
	    }
	}

	li {
		&.sg-management__manager.sg-manager__3el {
			width: 33%;
			width: calc(33% - 32px);
			margin-right: 0;
		}
		&.sg-management__manager.sg-manager__2el {
			margin-right: 0;
		}
	}
	
	
	.sg-management__manager {
	    width: 50%;
	    width: calc(50% - 32px);
	    width: -moz-calc(50% - 32px);
	    overflow: hidden;
	    display: inline-block;
	    padding: 20px 0;
	    float: left;
	    height: 172px;
		border-bottom: 1px dotted gray;
		&:nth-child(odd) {
			 margin-right: 30px;
		}
		&:nth-child(even) {
			 margin-left: 30px;
		}
	}
}

@include media-breakpoint-down(md) {
	.sg-management__managerpicture {
	    height: auto;
	    overflow: hidden;
	    float: left;
	    margin-right: 20px;

	    &.sg-management__managerpicture__sm {
	    	max-width: 66px;
	    }
	    img {
	    	width: 100%;
	    }
	}

	.sg-management__manager {
	    width: 100%;
	    overflow: hidden;
	    display: inline-block;
	    padding: 20px 0;
	    float: left;
	    height: auto;
			border-bottom: 1px dotted gray;
			figure {
				margin-bottom: 0;
			}
			p {
				margin-bottom: 0;
			}

	}
}
