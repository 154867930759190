@include media-breakpoint-up(lg) {

  .hamburger {
    position: absolute;
    top: 32px;
  }

  .fat-nav {
    top: 40px;
    left: 0;
    position: absolute;
  }
  .fat-nav__wrapper {
    padding-top: 160px;
  }
  .fat-nav__item {
    margin-left: 15px;
    &::before {
      content: "-";
      margin-left: -15px;
      padding-right: 8px;
      color: rgba(255,255,255, .5);
    }
  }
}
