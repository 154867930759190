//
// Poppins Semi Bold
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Semi Bold'),
       local('Poppins-SemiBold'),
       url('#{$poppins-font-path}/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
