/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */

/* Special class on .container surrounding .navbar, used for positioning it into place. */
@include media-breakpoint-up(lg) {
  .navbar-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color:transparent;
    box-shadow: none;
    svg {
      height: auto;
      float: none;
      margin-left: 0;
    }
  }
}
