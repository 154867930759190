#contatti-particles {
    position: absolute;
    width: 100%;
    height: 100%;
    //background-color:lighten( $dark, 15%);
    //background-color: $blue;
    //background-color: darken($blue, 15%);
    // background-image:     radial-gradient(
    //   circle at top right,
    //   lighten(#666, 10%),
    //   darken(#666, 10%)
    // );
    //background-color: #00356B;
       background-image: linear-gradient(to right, $dark, lighten($dark, 15%), lighten($dark, 20%),  lighten($dark, 5%));
    //background-image: linear-gradient(to right, #666,darken(#666, 5%), darken(#666, 10%), darken(#666, 15%));
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }

  #faq-particles {
   position: absolute;
   width: 100%;
   height: 100%;
   //background-color:lighten( $dark, 15%);
   //background-color: $blue;
   //background-color: darken($blue, 15%);
   // background-image:     radial-gradient(
   //   circle at top right,
   //   lighten(#666, 10%),
   //   darken(#666, 10%)
   // );
   //background-color: #00356B;
      background-image: linear-gradient(to right, $dark, lighten($dark, 15%), lighten($dark, 20%),  lighten($dark, 5%));
   //background-image: linear-gradient(to right, #666,darken(#666, 5%), darken(#666, 10%), darken(#666, 15%));
   background-repeat: no-repeat;
   background-size: cover;
   background-position: 50% 50%;
 }

#siti-impianti-particles {
   position: absolute;
   width: 100%;
   height: 100%;
   //background-color:lighten( $dark, 15%);
   //background-color: $blue;
   //background-color: darken($blue, 15%);
   // background-image:     radial-gradient(
   //   circle at top right,
   //   lighten(#666, 10%),
   //   darken(#666, 10%)
   // );
   background-color: #00356B;
     // background-image: linear-gradient(to right, $dark, lighten($dark, 15%), lighten($dark, 20%),  lighten($dark, 5%));
   //background-image: linear-gradient(to right, #666,darken(#666, 5%), darken(#666, 10%), darken(#666, 15%));
  background-image: url('../images/topographic-map-pattern-darker.png');
   //background-repeat: no-repeat;
   //background-size: cover;
   background-position: 50% 50%;
 }