//
// Poppins Bold
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Bold'),
       local('Poppins-Bold'),
       url('#{$poppins-font-path}/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
