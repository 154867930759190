// expanding search
.search_container{
  
  .searchbox{
      position:relative;
      min-width:40px;
      width:0%;
      height:40px;
      float:right;
      overflow:hidden;

      -webkit-transition: width 0.3s;
      -moz-transition: width 0.3s;
      -ms-transition: width 0.3s;
      -o-transition: width 0.3s;
      transition: width 0.3s;
  }

  .searchbox-input{
      top:0;
      right:0;
      border:0;
      outline:0;
      background:$s-lightgray;
      width:100%;
      height:40px;
      margin:0;
      padding:0px 55px 0px 20px;
      font-size:16px;
      color:darken($s-green, 10%);
  }
  .searchbox-input::-webkit-input-placeholder {
      color: darken($s-green, 10%);
  }
  .searchbox-input:-moz-placeholder {
      color: darken($s-green, 10%);
  }
  .searchbox-input::-moz-placeholder {
      color: darken($s-green, 10%);
  }
  .searchbox-input:-ms-input-placeholder {
      color: darken($s-green, 10%);
  }

  .searchbox-icon,
  .searchbox-submit{
      width:40px;
      height:40px;
      display:block;
      position:absolute;
      top:0;
      font-size:35px;
      right:0;
      padding:0;
      margin:0;
      border:0;
      outline:0;
      line-height:40px;
      text-align:center;
      cursor:pointer;
      background:$s-green;
  }

  .searchbox-open{
      width:100%;
  }

}

  .searchbox-icon--svg {
    fill:#fff;
    &:hover {
      fill: $s-gray;
    }
  }
