/*
    Created on : Feb 02, 2018, 6:30:10 PM
    Author     : Massimo Pescuma (http://massimopescuma.com)
*/

//
// VARIABLES
//

//
// Settings

// The class name to contain everything within.

$prefix-class: daterangepicker;
$arrow-size:     7px !default;

//
//box-shadow
$daterangepicker-shadow:                    rgba(0, 0, 0, 0.3) 0px 0px 20px 0px !default;
//
// Colors
$daterangepicker-color:                      inherit !default;
$daterangepicker-bg-color:                   #fff !default;

$daterangepicker-cell-color:                 $daterangepicker-color !default;
$daterangepicker-cell-border-color:          transparent !default;
$daterangepicker-cell-bg-color:              $daterangepicker-bg-color !default;

$daterangepicker-cell-hover-color:           $daterangepicker-color !default;
$daterangepicker-cell-hover-border-color:    $daterangepicker-cell-border-color !default;
$daterangepicker-cell-hover-bg-color:        #eee !default;

$daterangepicker-in-range-color:             #000 !default;
$daterangepicker-in-range-border-color:      transparent !default;
$daterangepicker-in-range-bg-color:          #ebf4f8 !default;

$daterangepicker-active-color:               #fff !default;
$daterangepicker-active-bg-color:            $blue !default;
$daterangepicker-active-border-color:        transparent !default;

$daterangepicker-unselected-color:           #999 !default;
$daterangepicker-unselected-border-color:    transparent !default;
$daterangepicker-unselected-bg-color:        #fff !default;

//
// daterangepicker
$daterangepicker-width:          278px !default;
$daterangepicker-padding:        14px !default;
$daterangepicker-z-index:        3000 !default;

$daterangepicker-border-size:    1px !default;
$daterangepicker-border-color:   #999 !default;
$daterangepicker-border-radius:  4px !default;


//
// Calendar
$daterangepicker-calendar-margin:              $daterangepicker-padding !default;
$daterangepicker-calendar-bg-color:            $daterangepicker-bg-color !default;

$daterangepicker-calendar-border-size:         1px !default;
$daterangepicker-calendar-border-color:        $daterangepicker-bg-color !default;
$daterangepicker-calendar-border-radius:       $daterangepicker-border-radius !default;

//
// Calendar Cells
$daterangepicker-cell-size:           34px !default;
$daterangepicker-cell-width:          $daterangepicker-cell-size !default;
$daterangepicker-cell-height:         $daterangepicker-cell-size !default;

$daterangepicker-cell-border-radius:  $daterangepicker-calendar-border-radius !default;
$daterangepicker-cell-border-size:    1px !default;

//
// Dropdowns
$daterangepicker-dropdown-z-index: $daterangepicker-z-index + 1 !default;

//
// Controls
$daterangepicker-control-height:               38px !default;
$daterangepicker-control-line-height:          $daterangepicker-control-height !default;
$daterangepicker-control-color:                #555 !default;

$daterangepicker-control-border-size:          1px !default;
$daterangepicker-control-border-color:         #ccc !default;
$daterangepicker-control-border-radius:        0 !default;

$daterangepicker-control-active-border-size:   1px !default;
$daterangepicker-control-active-border-color:  #08c !default;
$daterangepicker-control-active-border-radius: $daterangepicker-control-border-radius !default;

$daterangepicker-control-disabled-color:       #ccc !default;

//
// Ranges
$daterangepicker-ranges-color:                #08c !default;
$daterangepicker-ranges-bg-color:             #f5f5f5 !default;

$daterangepicker-ranges-border-size:          1px !default;
$daterangepicker-ranges-border-color:         $daterangepicker-ranges-bg-color !default;
$daterangepicker-ranges-border-radius:        $daterangepicker-border-radius !default;

$daterangepicker-ranges-hover-color:          #fff !default;
$daterangepicker-ranges-hover-bg-color:       $daterangepicker-ranges-color !default;
$daterangepicker-ranges-hover-border-size:    $daterangepicker-ranges-border-size !default;
$daterangepicker-ranges-hover-border-color:   $daterangepicker-ranges-hover-bg-color !default;
$daterangepicker-ranges-hover-border-radius:  $daterangepicker-border-radius !default;

$daterangepicker-ranges-active-border-size:   $daterangepicker-ranges-border-size !default;
$daterangepicker-ranges-active-border-color:  $daterangepicker-ranges-bg-color !default;
$daterangepicker-ranges-active-border-radius: $daterangepicker-border-radius !default;

//
// STYLESHEETS
//
.#{$prefix-class} {
  position: absolute;
  color: $daterangepicker-color;
  background-color: $daterangepicker-bg-color;
  border-radius: $daterangepicker-border-radius;
  width: $daterangepicker-width;
  padding: $daterangepicker-padding;
  margin-top: $daterangepicker-border-size*8;
-webkit-box-shadow: $daterangepicker-shadow;
    box-shadow: $daterangepicker-shadow;
  // TODO: Should these be parameterized??
  top: 100px;
  left: 20px;

  .drp-buttons {
      clear: both;
      text-align: right;
      padding: 8px;
      border-top: 1px solid #ddd;
      line-height: 12px;
      vertical-align: middle;
  }
  .drp-selected {
      display: inline-block;
      padding-right: 10px;
  }
  .drp-buttons .btn {
      margin-left: 8px;
      padding: 4px 8px;
  }

  $arrow-prefix-size: $arrow-size;
  $arrow-suffix-size: ($arrow-size - $daterangepicker-border-size);

  &:before, &:after {
    position: absolute;
    display: inline-block;

    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  &:before {
    top: -$arrow-prefix-size;

    border-right: $arrow-prefix-size solid transparent;
    border-left: $arrow-prefix-size solid transparent;
    border-bottom: $arrow-prefix-size solid transparent;
  }

  &:after {
    top: -$arrow-suffix-size;

    border-right: $arrow-suffix-size solid transparent;
    border-bottom: $arrow-suffix-size solid $daterangepicker-bg-color;
    border-left: $arrow-suffix-size solid transparent;
  }

  &.opensleft {
    &:before {
      // TODO: Make this relative to prefix size.
      right: $arrow-prefix-size + 2px;
    }

    &:after {
      // TODO: Make this relative to suffix size.
      right: $arrow-suffix-size + 4px;
    }
  }

  &.openscenter {
    &:before {
      left: 0;
      right: 0;
      width: 0;
      margin-left: auto;
      margin-right: auto;
    }

    &:after {
      left: 0;
      right: 0;
      width: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.opensright {
    &:before {
      // TODO: Make this relative to prefix size.
      left: $arrow-prefix-size + 2px;
    }

    &:after {
      // TODO: Make this relative to suffix size.
      left: $arrow-suffix-size + 4px;
    }
  }

  &.dropup {
    margin-top: -5px;

    // NOTE: Note sure why these are special-cased.
    &:before {
      top: initial;
      bottom: -$arrow-prefix-size;
      border-bottom: initial;
      border-top: $arrow-prefix-size solid $daterangepicker-border-color;
    }

    &:after {
      top: initial;
      bottom:-$arrow-suffix-size;
      border-bottom: initial;
      border-top: $arrow-suffix-size solid $daterangepicker-bg-color;
    }
  }

  &.dropdown-menu {
    max-width: none;
    z-index: $daterangepicker-dropdown-z-index;
  }

  &.single {
    .ranges, .calendar {
      float: none;
    }
  }

  /* Calendars */
  &.show-calendar {
    .calendar {
      display: block;
    }
  }

  .calendar {
    display: none;
    max-width: $daterangepicker-width - ($daterangepicker-calendar-margin * 2);
    margin: $daterangepicker-calendar-margin;

    &.single {
      .calendar-table {
        border: none;
      }
    }

    th, td {
      white-space: nowrap;
      text-align: center;

      // TODO: Should this actually be hard-coded?
      min-width: 32px;
    }
  }

  .calendar-table {
    border: $daterangepicker-calendar-border-size solid $daterangepicker-calendar-border-color;
    padding: $daterangepicker-calendar-margin;
    border-radius: $daterangepicker-calendar-border-radius;
    background-color: $daterangepicker-calendar-bg-color;
  }

  table {
    width: 100%;
    margin: 0;
  }

  td, th {
    text-align: center;
    width: $daterangepicker-cell-width;
    height: $daterangepicker-cell-height;
    border-radius: $daterangepicker-cell-border-radius;
    border: $daterangepicker-cell-border-size solid $daterangepicker-cell-border-color;
    white-space: nowrap;
    cursor: pointer;

    &.available {
      &:hover {
        background-color: $daterangepicker-cell-hover-bg-color;
        border-color: $daterangepicker-cell-hover-border-color;
        color: $daterangepicker-cell-hover-color;
      }
    }

    &.week {
      font-size: 80%;
      color: #ccc;
    }
  }

  td {
    &.off {
      &, &.in-range, &.start-date, &.end-date {
        background-color: $daterangepicker-unselected-bg-color;
        border-color: $daterangepicker-unselected-border-color;
        color: $daterangepicker-unselected-color;
      }
    }

    //
    // Date Range
    &.in-range {
      background-color: $daterangepicker-in-range-bg-color;
      border-color: $daterangepicker-in-range-border-color;
      color: $daterangepicker-in-range-color;

      // TODO: Should this be static or should it be parameterized?
      border-radius: 0;
    }

    &.start-date {
      border-radius: $daterangepicker-cell-border-radius 0 0 $daterangepicker-cell-border-radius;
    }

    &.end-date {
      border-radius: 0 $daterangepicker-cell-border-radius $daterangepicker-cell-border-radius 0;
    }

    &.start-date.end-date {
      border-radius: $daterangepicker-cell-border-radius;
    }

    &.active {
      &, &:hover {
        background-color: $daterangepicker-active-bg-color;
        border-color: $daterangepicker-active-border-color;
        color: $daterangepicker-active-color;
      }
    }
  }

  th {
      font-size: 12px;
      font-weight: 400;
      color: #999;
    &.month {
      width: auto;
      font-size: 18px;
      color: #111;
    }
  }

  //
  // Disabled Controls
  //
  td, option {
    &.disabled {
      color: #999;
      cursor: not-allowed;
      text-decoration: line-through;
    }
  }

  select {
    &.monthselect, &.yearselect {
      font-size: 12px;
      padding: 1px;
      height: auto;
      margin: 0;
      cursor: default;
    }

    &.monthselect {
      margin-right: 2%;
      width: 56%;
    }

    &.yearselect {
      width: 40%;
    }

    &.hourselect, &.minuteselect, &.secondselect, &.ampmselect {
      width: 50px;
      margin-bottom: 0;
    }
  }

  //
  // Text Input Controls (above calendar)
  //
  .input-mini {
    border: $daterangepicker-control-border-size solid $daterangepicker-control-border-color;
    border-radius: $daterangepicker-control-border-radius;
    color: $daterangepicker-control-color;
    height: $daterangepicker-control-line-height;
    line-height: $daterangepicker-control-height;
    display: block;
    vertical-align: middle;

    // TODO: Should these all be static, too??
    margin: 0 0 5px 0;
    padding: 0 6px;
    width: 100%;

    &.active {
     // border: $daterangepicker-control-active-border-size solid $daterangepicker-control-active-border-color;
      border-radius: $daterangepicker-control-active-border-radius;
    }
  }

  .daterangepicker_input {
    position: relative;

    i {
      position: absolute;

      // NOTE: These appear to be eyeballed to me...
      //left: 8px;
     // top: 8px;
    right: 8px;
    top: auto;
    bottom: 2px;
    color: $blue;
    font-size: 24px;
    }
  }
  &.rtl {
    .input-mini {
      padding-right: 28px;
      padding-left: 6px;
    }
    .daterangepicker_input i {
      left: auto;
      right: 8px;
    }
  }

  //
  // Time Picker
  //
  .calendar-time {
    text-align: center;
    margin: 5px auto;
    line-height: $daterangepicker-control-line-height;
    position: relative;
    padding-left: 28px;

    select {
      &.disabled {
        color: $daterangepicker-control-disabled-color;
        cursor: not-allowed;
      }
    }
  }
}

//
// Predefined Ranges
//

.ranges {
  font-size: 11px;
  float: none;
  margin: 16px 4px;
  text-align: left;

  ul {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }

  li {
    font-size: 13px;
    background-color: $daterangepicker-ranges-bg-color;
    border: $daterangepicker-ranges-border-size solid $daterangepicker-ranges-border-color;
    border-radius: $daterangepicker-ranges-border-radius;
    color: $daterangepicker-ranges-color;
    padding: 3px 12px;
    margin-bottom: 8px;
    cursor: pointer;

    &:hover {
      background-color: $daterangepicker-ranges-hover-bg-color;
      border: $daterangepicker-ranges-hover-border-size solid $daterangepicker-ranges-hover-border-color;
      color: $daterangepicker-ranges-hover-color;
    }

    &.active {
      background-color: $daterangepicker-ranges-hover-bg-color;
      border: $daterangepicker-ranges-hover-border-size solid $daterangepicker-ranges-hover-border-color;
      color: $daterangepicker-ranges-hover-color;
    }
  }

}



/*  Larger Screen Styling */
@media (min-width: 564px) {
  .#{$prefix-class} {
    width: auto;

    .ranges {
      ul {
        width: 160px;
      }
    }

    &.single {

      .ranges {
        ul {
          width: 100%;
        }
      }

      .calendar.left {
        clear: none;
      }

      &.ltr {
        .ranges, .calendar {
          float:left;
        }
      }
      &.rtl {
        .ranges, .calendar {
          float:right;
        }
      }
    }

    &.ltr {

      direction: ltr;
      text-align: left;
      .calendar{

        &.left {

          clear: left;
          margin-right: 0;

          .calendar-table {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        &.right {
          margin-left: 0;

          .calendar-table {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      .left .daterangepicker_input {
        margin-right: 12px;
      }

      .calendar.left .calendar-table {
        padding-right: 12px;
      }

      .ranges, .calendar {
        float: left;
      }
      .drp-calendar {
        float: left;
      }
    }
    &.rtl {
      direction: rtl;
      text-align: right;
      .calendar{
        &.left {
          clear: right;
          margin-left: 0;

          .calendar-table {
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &.right {
          margin-right: 0;

          .calendar-table {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      .left .daterangepicker_input {
        padding-left: 12px;
      }

      .calendar.left .calendar-table {
        padding-left: 12px;
      }

      .ranges, .calendar {
        text-align: right;
        float: right;
      }
    }
  }
}

@media (min-width: 730px) {
  .#{$prefix-class} {
    .ranges {
      width: auto;
    }
    &.ltr {
      .ranges {
        float: left;
      }
    }
    &.rtl {
      .ranges {
        float: right;
      }
    }

    .calendar.left {
      clear: none !important;
    }
  }
}

.prev, .next {
    i {
        color: $daterangepicker-active-bg-color;
        font-size: 24px;
    }
}
.daterangepicker .calendar-table .prev span {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table .next span {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
    color: #fff;
    border: solid $daterangepicker-active-bg-color;
    border-width: 0 2px 2px 0;
    border-radius: 0;
    display: inline-block;
    padding: 3px;
}
