.hamburger {
  display: block;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 32px;
  right: 0;
  z-index: 10000;
  padding: 12px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: transparent;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.hamburger__icon {
  position: relative;
  margin-top: 7px;
  margin-bottom: 7px;
}

.hamburger__icon, .hamburger__icon:before, .hamburger__icon:after {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #fff;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  -webkit-transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}
.color-logo .hamburger__icon, .color-logo .hamburger__icon:before, .color-logo .hamburger__icon:after {
  background-color: #a1a0a4;
}


.hamburger__icon:before, .hamburger__icon:after {
  position: absolute;
  content: "";
}
.hamburger__icon:before {
  top: -7px;
}
.hamburger__icon:after {
  top: 7px;
}
.active .hamburger__icon {
  background-color: transparent;
}
.active .hamburger__icon:before, .active .hamburger__icon:after {
  background-color: #fff;
}
.active .hamburger__icon:before {
  -moz-transform: translateY(7px) rotate(45deg);
  -ms-transform: translateY(7px) rotate(45deg);
  -webkit-transform: translateY(7px) rotate(45deg);
  transform: translateY(7px) rotate(45deg);
}
.active .hamburger__icon:after {
  -moz-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -webkit-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}

.fat-nav {
  top: 40px;
  left: 0;
  z-index: 999;
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background: #666;
  -moz-transform: scale(.2);
  -ms-transform: scale(.2);
  -webkit-transform: scale(.2);
  transform: scale(.2);
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.fat-nav__wrapper {
  width: 100%;
  height: 100%;
  display: table;
  table-layout: fixed;
  padding-top: 160px;
}
.fat-nav.active {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.fat-nav__title {
  color:white;
}

.fat-nav__list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.fat-nav__item {
  margin-left: 15px;
  line-height: 20px;
  margin-bottom: 10px;
  &::before {
    content: "-";
    margin-left: -15px;
    padding-right: 8px;
    color: rgba(255,255,255, .5);
  }
  a {
    color: rgba(255,255,255, .5);
    &:hover {
      color: rgba(255,255,255,1);
    }

  }
}