body {
  font-family: "raleway";
  margin-top: 76px;
}

::selection {
  background: lighten($green, 5%); /* WebKit/Blink Browsers */
  color: $white;
}
::-moz-selection {
  background: lighten($green, 5%); /* Gecko Browsers */
  color: $white;
}

/*//multilevel dropdown*/
.navbar-light .navbar-nav .nav-link {
    color: rgb(64, 64, 64);
}
.btco-menu li > a {
    padding: 10px 15px;
    color: #000;

}

.btco-menu .active a:focus,
.btco-menu li a:focus ,
.navbar > .show > a:focus{
    background: transparent;
    outline: 0;
}


.dropdown-menu .show > .dropdown-toggle::after{
    transform: rotate(-90deg);
}

// back to top button
#scroll {
    position:fixed;
    z-index: 999999999;
    right:40px;
    bottom:40px;
    cursor:pointer;
    width:30px;
    height:30px;
    background-color:#666;
    text-indent:-9999px;

    -webkit-border-radius:60px;
    -moz-border-radius:60px;
    border-radius:60px;
    box-shadow: 0 0 20px 0 rgba(25, 25, 25, .5);
}

#scroll.to-top-show {
  transition: all .2s 0.1s ease-in-out;
  transform: scale(2);
  opacity: 1;
}

#scroll.to-top-hide {
  transition: all .2s 0.1s ease-in-out;
  transform: scale(.0001);
  opacity: 0;
}

#scroll span {
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-4px;
    margin-top:-6px;
    height:0;
    width:0;
    border:4px solid transparent;
    border-bottom-color:#ffffff;
}
#scroll.to-top-show:hover {
    background-color:$green;
    opacity:1;filter:"alpha(opacity=100)";
    -ms-filter:"alpha(opacity=100)";
}



.text-editor {
  color: rgb(33, 37, 41);
  font-family: raleway;
  font-size:16px;
  font-weight:400;
  line-height:24px;
}


@include media-breakpoint-up(lg) {
  body {
    margin-top: 0px;
  }
}

//reset the container max-width for small tablets and uphones
@include media-breakpoint-only(sm) {
 .container{
   max-width: 100%;
 }
}
@include media-breakpoint-only(md) {
 .container{
   max-width: 100%;
 }
}

.s__heading {
	font-weight: 400;
	position: relative;
	margin-bottom: 40px;
}

.s__heading--green-line {
	&::before {
		content: "";
		height: 2px;
	    width: 30px;
	    background: $s-green;
	    position: absolute;
	    bottom: -20px;
	}
  a {
      &:hover {
      text-decoration: none;
    }
  }
 }

 .s__heading--blue-line {
	&::before {
		content: "";
		  height: 2px;
	    width: 30px;
	    background: $s-blue;
	    position: absolute;
	    bottom: -20px;
	}
  a {
      &:hover {
      text-decoration: none;
    }
  }
 }

 .s__paragraph {
	position: relative;
}

.s__paragraph--green-line {
	&::before {
		content: "";
		height: 2px;
	    width: 30px;
	    background: $s-green;
	    position: absolute;
      left: -45px;
      top: 15px;
	}
 }
 .s__paragraph--blue-line {
	&::before {
		content: "";
		height: 2px;
	    width: 30px;
	    background: $s-blue;
	    position: absolute;
      left: -45px;
      top: 15px;
	}
 }


.s__heading--blue-line {
  &::before {
    content: "";
    height: 2px;
      width: 40px;
      background: $blue;
      position: absolute;
      bottom: -20px;
  }
 }

.s__heading--darkgray {
	color: $s-darkgray;
 }

 .s__heading--white {
	color: white;
 }

.ra-666 {
    background-image: url(../images/svg/rightarrow-666.svg);
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 12px;
}

.ra-fff {
    background-image: url(../images/svg/rightarrow-fff.svg);
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 12px;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.carousel-control-prev-icon {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}

.carousel-control-prev-icon, .carousel-control-next-icon {
    width: 40px;
    height: 40px;
}

a {
		.right-arrow {
		top: 50%;
		right: 25px;
		transform: translateY(-50%);
		transition: all ease-in-out .15s;

		}
	&:hover {
		text-decoration: none;
	.right-arrow {
			right: 15px;

		}
	}	
}
.btn-ra {
	position: relative !important;
	padding-right: 3rem !important;
	.right-arrow {
		top: 50%;
		right: 25px;
		transform: translateY(-50%);
		transition: all ease-in-out .15s;
		}
	&:hover {
		text-decoration: none;
	.right-arrow {
		right: 15px;

		}
	}	
}


.la-fff {
  background-image: url(../images/svg/rightarrow-fff.svg);
  transform: rotate(180deg);
  display: inline-block;
  width: 7px;
  height: 12px;
  margin-right: 12px;
}

.ra-green {
    background-image: url(../images/svg/rightarrow-green.svg);
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 12px;
}


.doc-fff {
  background-image: url(../images/svg/doc-white.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: -5px;
  vertical-align: middle;
  line-height: normal;
}

.pdf-fff {
  background-image: url(../images/svg/pdf-white.svg);
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: -5px;
  vertical-align: middle;
  line-height: normal;
}



.ra-blue {
    background-image: url(../images/svg/rightarrow-blue.svg);
    display: inline-block;
    width: 7px;
    height: 12px;
    margin-left: 12px;
}

.grayheader {
    height: 110px;
}

@include media-breakpoint-up(lg) {
  .color-logo {
      .s, .o, .g, .i, .n, .lower-swoosh {
          fill: $s-green;
      }
      .upper-swoosh {
          fill: #a1a0a4;
      }
  }
}

@include media-breakpoint-down(md) {
  .color-logo {
      .s, .o, .g, .i, .n, .lower-swoosh {
          fill: $white;
      }
      .upper-swoosh {
          fill: $white;
      }
  }
}


.white-logo {
    .s, .o, .g, .i, .n, .lower-swoosh, .upper-swoosh {
        fill: #fff;
    }

}

.ovl-1 {
    @include p-el(after, rgba(0, 0, 0, 0.1)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}
.ovl-2 {
    @include p-el(after, rgba(0, 0, 0, 0.2)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-3 {
    @include p-el(after, rgba(0, 0, 0, 0.3)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-4 {
    @include p-el(after, rgba(0, 0, 0, 0.4)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}


.ovl-5 {

    @include p-el(after, rgba(0, 0, 0, 0.5)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-6 {
    @include p-el(after, rgba(0, 0, 0, 0.6)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-7 {
    @include p-el(after, rgba(0, 0, 0, 0.7)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-8 {
    @include p-el(after, rgba(0, 0, 0, 0.8)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}

.ovl-9 {
    @include p-el(after, rgba(0, 0, 0, 0.9)) {
    }
    div {
      position:relative;
      z-index: 1;
    }
}


.ff-poppins {
  font-family: 'Poppins';
  &.fw-300 {
    font-weight: 300;
  }
  &.fw-400 {
    font-weight: 400;
  }
  &.fw-500 {
    font-weight: 500;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.fw-700 {
    font-weight: 700;
  }
  &.fw-900 {
    font-weight: 900;
  }
}
.font-poppins {
  font-family: 'Poppins';
}

.header-img-container {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.35);
  }
}


.sogin-jumbotron {
  .sogin-jumbotron-heading {
	margin-top: -(95px / 2);
    top: 50%;
    color: white;
    text-align: left;
    width: 100%;


  }
  .img {
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.35);
    }
  }
  .jumbotron-h1{
    font-weight: 200;
  }
}
@include media-breakpoint-down(xs) {
  .sogin-jumbotron {
	.sogin-jumbotron-heading {
		margin-top: -(28px / 2);
		h1 {
			&.s__heading {
				margin-bottom: 0;
		  		font-size: 1.5rem;
		}
	  }
	}
  }
}
.btn-linkedin {
  &:before {
    content: "g";
    font-family: "social-share-kit" !important;
    color: white;
    line-height: 1em;
  }
}



@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
   max-width:1024px;
  }
}

/**** haking the badges ******/
.s-badge {
  &.badge {
    font-size: 14px;

    &.text-yellow {
      color: #ff9800;
    }
    &.bg-yellow {
      background-color: #ff9800;
    }
    &.text-green {
      color: #ff9800;
    }
    &.bg-green {
      background-color: $green;
    }
    &.text-blue {
      color: $green;
    }
    &.bg-blue {
      background-color: #0074a0;
    }
    &.text-purple {
      color: #9C27B0;
    }
    &.bg-purple {
      background-color: #9C27B0;
    }

    &.text-gray {
      color: #666666;
    }
    &.bg-gray {
      background-color: #666666;
    }
  }

}

/**** / haking the badges ******/

/*** tips vecchie usate nella prima versione ****/
.tip {
  background: white;
  display: inline-block;
  position: relative;
  padding: 4px 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  border-bottom: 2px solid #dcdcdc;
  &:before {
    position:absolute;
    top: 19px;
    left: -7px;
    content: '';
    border-left:7px solid transparent;
    border-bottom:7px solid transparent;
    border-top:7px solid transparent;
    transform: rotate(45deg);
    &.blue {
      box-shadow:  3px 2px  0 #0098d2;
    }
    &.yellow {
      box-shadow:  3px 2px  0 #ff9800;
    }
  }
  &.blue {
    color: $white;
    background:$blue;
    &:before {

      border-right:7px solid $blue;
    }
  }
  &.yellow {
    color: $white;
    background: #ff9800;
    &:before {
      border-right:7px solid #ff9800;
    }
  }
  &.green {
    color: $white;
    background: $green;
    &:before {
      border-right:7px solid $green;
    }
  }
  &.red {
    color: $white;
    background: $red;
    &:before {
      border-right:7px solid $red;
    }
  }
}

/*** / tips vecchie usate nella prima versione ****/

/*s-circleFocus3 s-circleFocus2*/
.s-circleFocus3 .circle, .s-circleFocus2 .circle {
    width: 140px;
    height: 140px;
    border-radius: 70px;
    background-color: #666;
    font-weight: 700;
    text-align: center;
}

.s-circleFocus3 .circle-testo, .s-circleFocus2 .circle-testo {
    color: #fff;
    font-size: 80%;
    font-weight: 600;
}

.s-circleFocus2 a .circle:hover {
    background-color: #333;
}

.s-circleFocus2 a:hover {
    text-decoration: none;
}

/*s-boxIcon*/
.s-boxIcon .boxIcon {
    color: #212529;
}




.boxIcon {
  svg {
    width: 75px;
    height: 75px;
  }
  .ico-codice-etico {
    fill: $green;
  }
}



/*s-boxText collapse*/
.s-boxText .collapse {
    height: 3rem;
    position: relative;
    display: block;
    overflow: hidden;
}

.s-boxText a.collapsed:after, .s-boxText a:not(.collapsed):after {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
}

.s-boxText .collapse.show {
    height: auto;
}

/*        .s-boxText .collapse.show:before {
            display: none;
        }

.s-boxText a.collapsed:after {
    content: '+ Show More';
}

.s-boxText a:not(.collapsed):after {
    content: '- Show Less';
}*/

.s-boxText a:hover {
    cursor: pointer;
}

/*s-tagCloud*/
.s-tagCloud ul {
    margin: 1em 0;
    padding: .5em 10px;
    text-align: center;
}

.s-tagCloud li {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline;
}

    .s-tagCloud li a {
        text-decoration: none;
        color: #fff;
        padding: 0 2px;
    }

.tag1 {
    font-size: 100%;
}

.tag2 {
    font-size: 120%;
}

.tag3 {
    font-size: 140%;
}

.tag4 {
    font-size: 160%;
}

.tag5 {
    font-size: 180%;
}


.row {
  &.no-gutters {
    margin-right: -15px;
    margin-left: -15px;

    & > [class^="col-"],
    & > [class*=" col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}


// katia's stylesheet
.card-header a {
  cursor:pointer;
}

.card-header .ra-fff {
   transform: rotate(270deg);
}
.card-header .collapsed .ra-fff{
  transform: rotate(90deg);
}

.s-organigramma {
    height: 102px;
    border: 1px solid #60b146;
    border-bottom: 6px solid #60b146;
}
.nav-link.disabled {
    font-weight: bold;
}
.s-boxIconBG{
    background: #f0f0f0;
}
.s-boxHover{
       min-height: 185px;
    padding-top: 25px;
}
.s-boxHover:hover{
    background: #c1c1c1;
    cursor: pointer;
}
.glossary_widget .alphaList{
	display: inline-flex;
	list-style:none;
}
.glossary_widget .actionList{
	list-style:none;
}

.glossary_widget .alphaList li {
    margin-right: 15px;
}

.glossary_widget .alphaList .active {
    font-weight: bold;
}

.breadcrumb.transparent-bg-breadcrumb .dropdown-menu{
  .dropdown-divider {
    border-color: rgba(255, 255, 255, .3);
  }
}



/*sharing buttons*/

.share{
  display:block;
  width:60px;
  height:60px;
  background:#ff9100;
  position:absolute;
  top:30px;
  right:0;
  z-index:99999;
  text-align:center;
  overflow:hidden;
  line-height:60px;
  font-size:1.5em;
  color:#fff;
  -moz-box-shadow: 0px 3px 9px rgba(0,0,0,.5);
-webkit-box-shadow: 0px 3px 9px rgba(0,0,0,.5);
box-shadow: 0px 3px 9px rgba(0,0,0,.5);
  border-radius:50px;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
}

.share:hover{
  cursor:pointer;
}

.one, .two, .three, .four, .five{
  position:absolute;
  width:60px;
  height:60px;
  color:#fff;
  border-radius:60px;
  text-align:center;
  line-height:60px;
  font-size:1.5em;
  top:-30px;
  right:0;
  z-index:9999;
   -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  -webkit-box-shadow: 0px 3px 9px rgba(0,0,0,.2);
box-shadow: 0px 3px 9px rgba(0,0,0,.2);
  -webkit-transition:transform  .3s ease-in-out;
  transition:transform .3s ease-in-out;
}

.one:hover, 
.two:hover,
.three:hover,
.four:hover, 
.five:hover{
  cursor:pointer;
}

.one i, .two i, .three i, .four i, .five i{
  display:none;
}

.one{
  background:#3B5998;
}

.two{
  background:#1DA1F2;
}

.three{
  background:#1c87bd;
}

.four{
  background:#34AF23;
}

.five{
  background:#666;
}


.related-documents {
  .related-documents-icon {
    width: 24px;
    fill: $blue;
  }

}

.top-half-white-bottom-half-gray {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,eaeaea+50,eaeaea+100 */
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 50%, #eaeaea 50%, #eaeaea 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 50%,#eaeaea 50%,#eaeaea 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #ffffff 0%,#ffffff 50%,#eaeaea 50%,#eaeaea 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eaeaea',GradientType=0 ); /* IE6-9 */
}

//
// Stretched link
//

.stretched-link {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    // Just in case `pointer-events: none` is set on a parent
    pointer-events: auto;
    content: "";
    // IE10 bugfix, see https://stackoverflow.com/questions/16947967/ie10-hover-pseudo-class-doesnt-work-without-background-color
    background-color: rgba(0, 0, 0, 0);
  }
}

.hover-ico-box {
  transition: .3s all ease-in-out;
  padding-top: 1em;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 40px 0;
    //box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 50px;
    background: $chiaro;
    border-radius: 2px;
    svg {
      &.ico-lightblue {
        transition: .75s all ease-in-out;
        fill: $blue;
      }
      &.ico-green {
        transition: .75s all ease-in-out;
        fill: darken($green, 5%);
      }
    }
    a {
      transition: .3s all ease-in-out;
      text-decoration: none;
    }
  }
}

.hover-ico-box-reverse {
  transition: .3s all ease-in-out;
  padding-top: 1em;
  a {
    color: rgba(255, 255, 255, 0)!important;
    font-weight: 500;
    text-shadow: none;
    text-decoration: none;
    &:before {
      color: $dark;
      font-weight: 800;
      content: attr(data-hover);
      position: absolute;
      transition: transform 0.5s, opacity 0.3s;
    }
    &:hover {
      color: rgba(255, 255, 255, 1)!important;
    }
    &:hover,
    &:focus {
      outline: none;
      &:before {
        transform: scale(0.9);
        opacity: 0;
      }
    }
  }
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0 40px 0;
    
    border-radius: 4px; 
    svg {
        transition: .3s all ease-in-out;
        fill: rgba(255, 255, 255, 1);
     
    }
  }
  &.hover-ico-blue {
    &:hover {
      background: $blue;
    }
    }
  &.hover-ico-green {
    &:hover {
      background: $green;
    }
  }
}






.s-tabs {
  &.s-tabs-green{
    .selector{
    background: $green;
    background: -moz-linear-gradient(45deg, $green 0%, darken($green, 10%) 100%);
    background: -webkit-linear-gradient(45deg, $green 0%, darken($green, 10%) 100%);
    background: linear-gradient(45deg, $green 0%, darken($green, 10%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green', endColorstr='darken($green, 10%)',GradientType=1 );
    }
    a {
      color: $green;
    }
  }
  position: relative;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding:0px;
  background:#fff;
  box-shadow:0 5px 16px rgba(0,0,0,.2);
  display:inline-flex;
  border-radius:50px;
  position:relative;
  border: 3px solid white;
  .selector{
    height:100%;
    display:inline-block;
    position:absolute;
    left:0px;
    top:0px;
    z-index:1;
    border-radius:50px;
    transition-duration:0.6s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    
    background: #05abe0;
    background: -moz-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
    background: -webkit-linear-gradient(45deg, #05abe0 0%,#8200f4 100%);
    background: linear-gradient(45deg, $blue 0%, darken($blue, 10%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );

  }
  
  a{
    text-decoration:none;
    color: $blue;
    text-transform:uppercase;
    font-weight: 600;
    padding:10px 25px;
    display:inline-block;
    position:relative;
    z-index:1;
    transition-duration:0.6s;
    &.active{
      color:#fff;
    }
  }
  
  .nav-tabs {
     border-bottom: 1px solid transparent;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
      color: white;
      background-color: transparent;
      border-color: transparent;
  }
  .nav-tabs .nav-link {
    &:hover {
      border-color: transparent;
    }
  }

  


}



@include media-breakpoint-down(sm) { 

.s-tabs {
	a {
		font-size: .6em;
		padding: 10px 10px;
	}
	}

}


//gestione dell'animazione dei panelli 
.tab-content {
  position: relative;
  overflow: hidden;
  &.is-animating {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    width: auto;
  }
  > .tab-pane {
    display: block;
  }
}

.tab-pane {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: 100%;
  opacity: 0;
  transform: translateX(100%) translateY(0%);
  &.is-active {
    position: relative;
    opacity: 1;
    transform: translateX(0%);
  }
  &.is-exiting {
    opacity: 0;
    transform: translateX(-100%);
  }
  &.is-animating {
    transition: opacity 400ms ease-out, transform 400ms ease-out;
  }
}

.bb-dotted__gray {
  border-bottom: 1px dotted gray;
}

.table .thead-green th {
  color: #fff;
  background-color: $green;
  border-color: $green;
}


.related-documents {
  .related-documents-item {
    
    flex: calc(50% - 20px);
    margin-right: 20px;
    
  }
}

@include media-breakpoint-down(xs) { 
	.related-documents {
	  .related-documents-item {
		flex: calc(100% - 20px);
		margin-right: 20px;
	  }
	}
 }


ul {
  &.green-bullet {
    list-style: none;
    padding-left: calc(1.4em + .7vw);
    list-style-image: url(/images/svg/bullet.svg);
    li{
      padding-left: .25em;
    }
  }
  &.blue-bullet {
    list-style: none;
    padding-left: calc(1.4em + .7vw);
    list-style-image: url(/images/svg/blue-bullet.svg);
    li{
      padding-left: .25em;
    }
  }
}