.show> .dropdown-menu.level1  {
  visibility: visible;
  opacity: 1;
  top: 0!important;
  &.dropdown-breadcrumb {
    top: 28px!important;
    left: -20px!important;
  }
}

.dropdown-menu.level1 {

  display: block;
  visibility: hidden;
  transition: top 0.4s ease-in-out, opacity 0.4s ease-in-out,  visibility 0.4s ease-in-out;
  overflow: hidden;
  opacity: 0;
  top: 40px!important;
  &.dropdown-breadcrumb {
      border-top: 1px solid lighten($primary, 1%)!important;
      top: 80px!important;
      left: -20px!important;
  }
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15)!important;
}
