.s-media-1 {
	background: $s-mediumgray;
}

.s-media-2 {

}
.s-media-2-blue {

}
.evento__holder {

}
