//section news
@include media-breakpoint-only(md) {
  .s-news {
    display: block;
    background: $s-lightgray;


    .btn {
    	margin: 0 2px 5px 0;
    }
  }

  #carouselNews {
    .carousel-indicators {
        justify-content: left;
        margin-left: 1.5rem !important;
      }
  }


  .news__img {
  	height: 500px;
  	overflow: hidden;
    position: relative;
    img {
        position: absolute;
        top: -9999px;
        bottom: -9999px;
        left: -9999px;
        right: -9999px;
        margin: auto;

    }
  }

}
