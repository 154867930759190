.s-partnership {
	background-image: url("../images/partner_bg.png");
	background-position: 50% 50%;
	background-repeat: repeat-x;
	&.s-partnership-green {
		background-image: url("../images/partner_bg-green.png");
	}
	.container {
		background:  white;
		border-bottom: 4px solid #dbdbdb;
		max-width: 1240px;;
	}
	img {
	height: 80px;
	  filter: url(#grayscale);
	  filter: grayscale(100%);
	  transition: all .7s;
	  &:hover {
		  filter:none;
		}
	}

}