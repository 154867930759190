/* Specific mapael css class are below
 * 'mapael' class is added by plugin
*/

.mapael .map {
  //background-color: #fafafa;
 position: relative;
}

/* For all zoom buttons */
.mapael .zoomButton {
    background-color: $primary;
    border: 1px solid transparent;
    color: $white;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    //border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 0;
    font-size: 1.75rem;
    right: 0;

    -webkit-user-select: none;
    -khtml-user-select : none;
    -moz-user-select: none;
    -o-user-select : none;
    user-select: none;
    transition: all .3s ease;
    &:hover {
      background-color: lighten($primary, 10%);
      box-shadow: 0 0 5px 0 rgba(0,0,0, .3);
    }
}

/* Reset Zoom button first */
.mapael .zoomReset {
    top: 10px;
}



/* Then Zoom In button */
.mapael .zoomIn {
    top: 45px;
}

/* Then Zoom Out button */
.mapael .zoomOut {
    top: 80px;
}

.mapael .mapTooltip {
    position: absolute;
    background-color: $primary;
    padding: 1.5rem 2.5rem;
    z-index: 1000;
    max-width: 350px;
    display: none;
    color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
}


path[data-id="Rome"] { 
  transform: matrix(0.3999, 0, 0, 0.3333, 101.262, 101.222);
  
}