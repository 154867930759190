
/* option 2 **************************************** */

.s-fornitori-2 {
    position:relative;
    &:after {
    	content: '';
    	position: absolute;
    	width: 100%;
    	height: 50px;
    	top: -50px;
    	right:0;
    	left: 0;
    }
    .fornitori-col{
      background-color: #fafafa;
    }
    .bandi-col{
    		position: relative;
    	    &:after {
			    	content: '';
			    	position: absolute;
			    	width: 20px;
			    	height: 2px;
			    	background-color: #c9e5f7;
			    	top: 0;
			    	right:0;
			    	left: -85px;
			    	z-index: 99999;
	    }
    }
		.btn {
		    margin: 0 2px 5px 0;
		}
}

.btn-outline-blue {
	border-width: 2px;
	border-color: $green;
	color: $green;
}
/* / option 2 **************************************** */
