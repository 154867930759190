/*** tema verde del ciclo nucleare *******/
@include media-breakpoint-up(lg) {
	.s-ciclo-nucleare {
			&.bg-success {
			.ciclo-nucleare--block {
				margin: -75px -115px;
				padding:  75px 115px;
				background: white;
				z-index: 2;
			    position: relative;
			}
			.ciclo-nucleare--item {
				svg {
					width: 180px;
				}
			}
		}
	}
}
