
#sogin-timeline {
	height:600px;
}

.tl-timenav-line {
	background-color: $blue!important;
	width:3px!important;
    //background-image: url(timeline.png?v4.4);
}

.tl-timemarker.tl-timemarker-active .tl-timemarker-content-container .tl-timemarker-content .tl-timemarker-text h2.tl-headline {
    color: $green;
    font-weight: 700;
}

.tl-timemarker.tl-timemarker-active .tl-timemarker-line-left:after, .tl-timemarker.tl-timemarker-active .tl-timemarker-line-right:after {
    background-color: $green;
}

.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-timespan:after {
    background-color: rgba(96,177,70,.5);
}

.tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-left:after, .tl-timemarker.tl-timemarker-with-end.tl-timemarker-active .tl-timemarker-line-right:after {
    background-color: $green!important;
}

.tl-timeaxis .tl-timeaxis-content-container .tl-timeaxis-major .tl-timeaxis-tick {
    color: $green;
	 font-weight: 700;
}

.storyjs-embed {
    background: $chiaro;
}