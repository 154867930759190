// _mixins.scss


// pseudo element overlay mixin

@mixin p-el($el, $el-background) {
  &:#{$el} {
    @extend %pseudos;
    	background: $el-background;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
    @content;
  }
}

@mixin font-weight ($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );
  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }
  font-weight: $output;
}
