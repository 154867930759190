// section chiusura del ciclo nucleare

//******************* stili delle icone in svg *************
.centrale{opacity:0.5;fill:$white;}
.finestra{fill:$white;}
.ruota{fill:$white;}
.braccio{fill:$white;}
.pala{fill:$white;}
.corpo{fill:$white;}
.cingoli{fill:$white;}
.cabina{fill:$white;}

.sicurezza{fill:$green;}

.centrale-scomparsa{opacity:0.5;fill:$green;}
.muletto{fill:$green;}

.face {fill:$green;}
.helmet {fill:$green;}
.body {fill:$green;}
.overalls {fill:$green;}

.s-ciclo-nucleare {
  background: $s-lightgray;

}

.ciclo-nucleare {
	background: white;

}

.ciclo-nucleare--block {
	margin: -75px -115px;
	padding:  75px 115px;
	background: white;
	z-index: 2;
    position: relative;
}

.ciclo-nucleare--item {
	@include transition(all .5s ease);
	&:hover {
		background: $green;
		h5 {
			@include transition(all .5s ease);
			color: #fff;
		}
		.finestra {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.terreno {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.56);
		}
		.ruota {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.centrale {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.56);
		}
		.braccio {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.pala {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.corpo {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.cingoli {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.cabina {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		
		.bidone {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}

		.sicurezza {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}

		.centrale-scomparsa {
			@include transition(all .5s ease);
			fill:#fff;
		}
		.muletto {
			@include transition(all .5s ease);
			fill:rgba(255, 255, 255, 0.8);
		}
		.face,
		.body,
		.helmet,
		.overalls {
			@include transition(all .5s ease);
			fill:#fff;
		}
	}
	a {
		text-decoration: none;
	}
}
