//section news
@include media-breakpoint-only(xl) {
  .s-news {
    display: block;
    background: $s-lightgray;
    padding-top: 3rem;
    padding-bottom: 3rem;


  }

  #carouselNews {
    .carousel-indicators {
        justify-content: left;
        margin-left: 25px;
      }
  	margin-top: -100px;
  	margin-bottom: -100px;
  	margin-right: -20px;
  	height: 475px;
    .row {

        margin-right: -15px;
        margin-left: -15px;

        & > [class^="col-"],
        & > [class*=" col-"] {
          padding-right: 0;
          padding-left: 0;
        }
      }

  }

  .news__container {
  	padding-left: 60px;
  }
  .news__img {
  	height: 475px;
  	overflow: hidden;
  }


}
