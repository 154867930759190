//
// Poppins Medium
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Medium'),
       local('Poppins-Medium'),
       url('#{$poppins-font-path}/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
