@include media-breakpoint-down(md) {// 767px
  .section-title {
    display:block;
    padding:1.75rem;
    cursor:pointer;
    color: $secondary;
    &:hover {
      background:#e4e4e4;
    }

    &:after {
      content:"";
      display: inline-block;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;
      vertical-align: middle;
      margin-left: 1rem;
      margin-top: .25rem;
      transform: translateY(-2px);
      transition: transform .2s ease-out;
    }
    &.open:after {
      content: "";
      transform: rotate(90deg) translateX(-3px);
    }
  } // .section-title

  .breadcrumb-phones {
    padding:1.75rem;
    margin:0;
    background:#fcfcfc;
    display:none;

    ol, li, a {
      float:none;
      color: $secondary;
    }

    li {
      display:block;
      float:none;
      &.current {
        color: rgba(134, 142, 150, 0.5);
      }
    }
    a:after {
      content: "";
    } // a


     > li {
      &:first-child,
      &.active,
      &.current {
        display:block;
      }



    } // > li
  }// .breadcrumb

}
