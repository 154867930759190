// set template
// possible values dark, bright
$template: dark;


// template colors
$bg-dark: #000;
$fg-dark: #999;
$bg-bright: #eeee99;
$fg-bright: #eeee99;


ul {
  &.org-chart {
    align-items:center;
    justify-content: center;
    width:100%;
    padding-left:0;
      a {
      text-decoration:none;
      color:green;
    }
    li {
      list-style: none;
      width:100%;
      > ul {
        padding:0;
        border-color: red;
      }
      ul {
        > li {
          padding:0;
        }
      }
      &.tier-1 {
        span {
          &.node {
            display:flex;
            height: 100%;
            background:#fff;
            border-top:3px solid #e7527c;
            border-left:1px solid rgba(122,122,122,1);
            border-bottom:1px solid rgba(122,122,122,1);
            padding: 1em;
            text-align: center;
            -webkit-box-shadow: 3px 3px 0px 0px rgba(122,122,122,1);
            -moz-box-shadow: 3px 3px 0px 0px rgba(122,122,122,1);
            box-shadow: 3px 3px 0px 0px rgba(122,122,122,1);
            position: relative;

            &:hover {
              background: $grigiochiaro;
            }
          }
        }
      }
    }
  }
  .employee-avatar{
      height: 100px;
  }
  .main-node {
    &:after {
      content:'';
      //display: block;
      width: 3px;
      height: 50%;
      background: lightgray;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-top: 4px;
    }
  }
  .tier-2-block {
    position:relative;
    &:after {
      content:'';
      //display: block;
      width: 3px;
      height: 100%;
      background: lightgray;
      position: absolute;
      left: 50%;
      top: 0;
    }
  }
  .tier-2-block {
    &.no-left {
        &:after {
        content:'';
        //display: block;
        width:0;
        height: 0;
      } 
    }

  }
  .tier-2-block {
    &:last-child {
        &:after {
        //display: block;
        height: 50%;
      }
    }
  }

  .cross {
    position:relative;
    &:after {
      content:'';
      //display: block;
      width: 100%;
      height: 3px;
      background: lightgray;
      position: absolute;
      left: 3px;
      top: 50%;
    }
  }
  .ul-spacer {
    position:relative;
    &:after {
      content:'';
      //display: block;
      width: 3px;
      height: 100%;
      background: lightgray;
      position: absolute;
      left: 50%;
      top: 0;
    }
    &.cr {
      &:before {
        content:'';
        //display: block;
        width: 3px;
        height: 100%;
        background: $blue;
        position: absolute;
        left: 80%;
        top: 3px;
      }   
    }
  }
}






/* Department/ section colors */
.department.dep-a a{ background: #FFD600; }
.department.dep-b a{ background: #AAD4E7; }
.department.dep-c a{ background: #FDB0FD; }
.department.dep-d a{ background: #A3A2A2; }
.department.dep-e a{ background: #f0f0f0; }