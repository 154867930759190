//questo componente è personalizzato secondo le necessità di design Sogin (due stili differenti di braducumbs in base alla pagina di approdo)

// mixin per convertire l'esadecimale in rgba aggiungendo opacità
@mixin text-opacity($color, $opacity: 0.5) {
    color: $color; /* The Fallback */
    color: rgba($color, $opacity);
}





.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: $breadcrumb-padding-y $breadcrumb-padding-x;
  margin-bottom: $breadcrumb-margin-bottom;
  list-style: none;
  //background-color: $breadcrumb-bg;
  @include border-radius($border-radius);

  li {
    &.breadcrumb-item {
      >  a {
        color: $secondary;
      }
    }
  }

  &.white-bg-breadcrumb{
    li {
      &.breadcrumb-item {
        >  a {
          color: $secondary;
        }
        + .breadcrumb-item::before {
          @include text-opacity($secondary, 0.5);
        }
      }
    &.active {
      //color: $breadcrumb-active-color;
      @include text-opacity($secondary, 0.5);
      }
    }
  }
  &.transparent-bg-breadcrumb{
    text-transform: uppercase;
    .dropdown-menu {
      background-color: $green;
      border: none;
      box-shadow: 0 0 15px 0 rgba($dark, .5);
      
    }
    a {
      &.dropdown-item {
        text-transform: none;
        color: rgba($white, .8);
        &:hover {
          color: $dark;
        }
        &:focus {
          color: $dark;
        }
      }
    }
    padding-left: 0;
    margin-bottom: 0;
    li {
      &.breadcrumb-item {
        >  a {
          color: $green;
          font-size: 1rem;
          font-weight: 500;

          &:after {
            color:$white;
          }
        }
        + .breadcrumb-item::before {
          @include text-opacity($white, 0.5);
        }
      }
    &.active {
      //color: $breadcrumb-active-color;
      @include text-opacity($white, 0.5);
      }
    }
  }

  .breadcrumb-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: $breadcrumb-item-padding;
      padding-left: $breadcrumb-item-padding;
      //color: $breadcrumb-divider-color;
      //color: rgba(255, 255, 255, .5);
      @include text-opacity($secondary, 0.5);
      content: "#{$breadcrumb-divider}";

    }

    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + .breadcrumb-item:hover::before {
      text-decoration: underline;
    }
    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
      text-decoration: none;
    }

    &.active {
      //color: $breadcrumb-active-color;
      //color: rgba(255, 255, 255, .5);
      @include text-opacity($secondary, 0.5);
    }
  }
}
