.s-bandi-btn {
    border: 2px solid #868e96;
    &:hover {
        background-color: #d1d0d0;
        text-decoration:none;
    }
}

.s-bg-state {
    border-width: 10px 10px 0 0;
    border-style: solid;
    border-color: #60b146 transparent transparent transparent;
    position: relative;
    top: 32px;
    left: -60px;
}

.s-border-state {
    border-bottom: 2px solid #868e96;
}


.s-bandi-di-gara {
    .form-control {
        background-color: #d3d3d3; 
        border: 1px solid #d3d3d3;
   }
}
