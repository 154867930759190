

#mainSlider,
#mobileSlider {
  .carousel-item {
    &:after {
        content:"";
        display:block;
        position:absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background:rgba(0,0,0,0.35);
      }
  }
}
