//section servizi
.s-servizi {
	background: $s-blue;
	padding: 1.5rem;
	padding-bottom: 2.5rem;
	background-image: url("../images/servizi_bg.jpg");
	background-position: 50%;

}


.service__item {
  color: #fff;
  position: relative;
  overflow: hidden;
  //margin: 10px 1%;
  min-width: 175px;
  //max-width: 310px;
  max-height: 200px; //riattivare la max-height per box rettangolari anzichè quadrati;
  width: 100%;
  background-color: #000000;
  text-align: center;
}
.service__item * {
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.service__item img {
		filter:grayscale(100%);
	  -moz-filter:grayscale(100%);
	  -webkit-filter:grayscale(100%);
  opacity: 0.7;
  max-width: 100%;
}
.service__item:after {
  background: $s_green;
  width: 0;
  height: 6px;
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.service__item figcaption {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.service__item h4 {
  margin: 0;
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
}

.service__item a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.service__item:hover img {
	opacity: 1;
  	-webkit-transform: scale(1.1);
  	transform: scale(1.1);
	filter:grayscale(0);
	-moz-filter:grayscale(0);
	-webkit-filter:grayscale(0);
}
.service__item:hover:after {
  width: 100%;
}



.service__item--horizontalspacer {
	height: 15px;
}
.service__item--horizontalspacerx4 {
	height: 15px*4;
}

.service__row--addmiddleline {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		background: white;
		height: 1px;
		width: 100%;
		z-index: 0;
	}
}
