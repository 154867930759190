.s-social{
	background: $s-lightgray;
	a {
		text-decoration: none;
		color: $white;
		 
		i {
			background-color: $s-gray;
			font-size: 1.25em;
			transition: all .5s ease-in-out;
			&.fa-youtube {
				&:hover {
					background-color: #f00;
				}
			}
			&.fa-linkedin-in {
				&:hover {
					background-color: #0077b5;
				}
			}
			&.fa-instagram {
				&:hover {
					background-color: #d6249f;
					//background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);

				}
			}
		}
	}
}

.yt {
	.yt_svg_rect {
		fill: $s-gray;
		transition: all .5s ease-in-out;
	}
	&:hover {
		.yt_svg_rect {
			fill: #f00;
		}
	}
}


.linkedin {
	.in_svg_rect {
		fill: $s-gray;
		transition: all .5s ease-in-out;
	}
	&:hover {
		.in_svg_rect {
			fill: #0077b5;
		}
	}
}


