#map_container{
  position: relative;
}
#map{
    height: 0;
    overflow: hidden;
    padding-bottom: 22.25%;
    padding-top: 30px;
    position: relative;

}

.s-map {
	 background-image: url(../images/mappa.jpg);
}

.s-trasparenza {
    background-image: url(../images/trust.jpg);
    background-size: cover;
}

.trasparenza__block {
    height: 600px;
    max-width: 100%;
    width: 100%;
}

.rms-img {
	border: solid 1px transparent !important;
	border-color: $chiaro!important;
	border-width: 10px !important;
}

.mt-minus-30 {
	margin-top: -3rem;
}

.si-img {
	opacity: .5;
}