//
// Poppins Regular / Normal
// ------------------------------------------------------

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Regular'),
       local('Poppins-Regular'),
       url('#{$poppins-font-path}/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
